import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"
import {document} from "postcss";

console.log('Hello World from application.js');

function toggleElement(element) {
    document.getElementById(element).classList.toggle('d-none');
}

window.toggleElement = toggleElement;