import { Controller } from "@hotwired/stimulus"
import {document} from "postcss";

//const root = document.getElementsByTagName( 'html' )[0];

export default class extends Controller {

  static targets = [
      "copyButton",
      "currentPasswordInput",
      "formToSubmit"
  ]

  static values = {
    targetForm: String
  }

  connect() {
    console.log("Common Controller connected");
  }

  toogleMenu() {
    root.classList.toggle("layout-menu-expanded");
  }

  submitForm(event) {
    this.formToSubmitTarget.submit();
    // const target_form = this.targetFormValue;
    // document.getElementById(target_form).submit();
  }

  copyText({ params: { content } }) {
    let copy_button = this.copyButtonTarget;
    console.log("##### " + content);
    try {
      navigator.clipboard.writeText(content);
      copy_button.classList.remove("btn-primary");
      copy_button.classList.add("btn-success");
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }

  showPassword() {
    let current_password_input = this.currentPasswordInputTarget;
    if (current_password_input.type === "password") {
      current_password_input.type = "text";
    } else {
      current_password_input.type = "password";
    }
  }

}
