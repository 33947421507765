import { Application } from "@hotwired/stimulus"
import Select2Controller from 'stimulus-select2'
import * as bootstrap from "bootstrap"
import * as ActiveStorage from "@rails/activestorage"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

application.register("select2", Select2Controller)

export { application }
